<template>
  <v-container
    id="portal-management"
    tag="section"
  >
    <base-material-card
      color="indigo"
      icon="mdi-book-open-page-variant-outline"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Marketing Materials
        </div>
      </template>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-container>
            <v-row v-if="snackColor === 'info'">
              <v-col
                cols="12"
                md="4"
                class="d-flex flex-column"
              >
                <v-skeleton-loader
                  v-bind="attrs"
                  type="article, actions"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-skeleton-loader
                  v-bind="attrs"
                  type="article, actions"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-skeleton-loader
                  v-bind="attrs"
                  type="article, actions"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row v-if="snackColor === 'info'">
              <v-col
                cols="12"
                md="4"
              >
                <v-skeleton-loader
                  v-bind="attrs"
                  type="article, actions"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-skeleton-loader
                  v-bind="attrs"
                  type="article, actions"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-skeleton-loader
                  v-bind="attrs"
                  type="article, actions"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row v-if="snackColor !== 'info' && items.length === 0">
              <v-col cols="12">
                <div>
                  <v-alert
                    outlined
                    color="blue darken-3"
                  >
                    <div style="font-size: 3rem; font-weight: 300, font-family: 'Roboto';">
                      Coming Soon!
                    </div>
                    <div style="font-size: 1.5rem; font-weight: 400, font-family: 'Roboto';">This is where you'll see useful material that you can use for social media campaigns or internal announcements. We don't have anything here right now, but check back soon, we're always adding new material!</div>
                  </v-alert>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="items.length >= 1 && snackColor !== 'info'" dense>
              <v-col
                v-for="(item, i) in items"
                :key="i"
                cols="12"
                md="4"
                sm="6"
                class="d-flex flex-column"
              >
                <v-card class="flex d-flex flex-column">
                  <div class="d-flex justify-space-between">
                    <div>
                      <v-card-title
                        v-if="item.name.length >= 20"
                        class="headline flex text-left"
                        v-text="item.name.substring(0,20) + '...'"
                      ></v-card-title>

                      <v-card-title
                        v-if="item.name.length >= 1 && item.name.length <= 19"
                        class="headline flex text-left"
                        v-text="item.name.substring(0,20)"
                      ></v-card-title>

                      <br>

                      <v-card-subtitle class="flex text-left" v-text="item.summary.substring(0,20) + '...'"></v-card-subtitle>

                      <v-card-actions style="position: relitave">

                        <v-btn
                          class="ml-2 mt-auto"
                          outlined
                          rounded
                          small
                          @click="goToDetails(item.uniqueID)"
                          style="position: absolute; bottom: 5px; right: 10px;"
                        >
                          See Details
                        </v-btn>
                      </v-card-actions>
                    </div>

                    <v-avatar
                      class="ma-3"
                      size="80"
                      tile
                    >
                      <v-img :src="item.imageUrl"></v-img>
                    </v-avatar>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <div class="text-center pt-2">
              <v-btn small rounded :disabled="(prevCursor === undefined || prevCursor === '') || snackColor === 'info'" color="blue darken-1" v-on:click="previousPage()"><v-icon >mdi-chevron-left</v-icon></v-btn>
              <v-btn small rounded :disabled="(nextCursor === undefined || nextCursor === '') || snackColor === 'info'" color="blue darken-1" v-on:click="nextPage()"><v-icon >mdi-chevron-right</v-icon></v-btn>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </base-material-card>
    <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
      <v-progress-circular
        v-if="snackColor === 'info'"
        color="white"
        indeterminate
      ></v-progress-circular>
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>

<script>

import GetItems from '@/Services/Marketing/CustMarketing'

export default {
  name: 'Catalogue',
  data: () => ({
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 2
    },
    nextCursor: '',
    prevCursor: '',
    items: [],
    snack: false,
    snackColor: '',
    snackTimeout: null,
    snackText: ''
  }),
  created () {
    this.init()
  },
  methods: {
    async init () {
      await GetItems.getMarketingItems()
        .then((res) => {
          if (res.status === 200) {
            this.$set(this, 'items', res.data.results)
            this.nextCursor = res.data.nextCursor
            this.prevCursor = res.data.previousCursor
          }
        })
    },
    goToDetails (id) {
      const url = '/marketing-catalogue/' + id + '/details'
      this.$router.push(url)
    },
    async previousPage () {
      this.snackLoading()
      await GetItems.previousPage(this.prevCursor)
        .then((res) => {
          if (res.status === 200) {
            this.$set(this, 'items', res.data.results)
            this.nextCursor = res.data.nextCursor
            this.prevCursor = res.data.previousCursor
            this.snackSuccess()
          }
        })
    },
    async nextPage () {
      this.snackLoading()
      await GetItems.nextPage(this.nextCursor)
        .then((res) => {
          if (res.status === 200) {
            this.$set(this, 'items', res.data.results)
            this.nextCursor = res.data.nextCursor
            this.prevCursor = res.data.previousCursor
            this.snackSuccess()
          }
        })
    },
    snackLoading () {
      this.snack = true
      this.snackColor = 'info'
      this.snackTimeout = -1
      this.snackText = 'Loading ...'
    },
    snackSuccess () {
      this.snack = true
      this.snackColor = 'success'
      this.snackTimeout = 3000
      this.snackText = 'Catalogue Loaded'
    },
    snackError (errorMessage) {
      this.snack = true
      this.snackColor = 'error'
      this.snackTimeout = 5000
      this.snackText = errorMessage
    }
  }
}
</script>

<style>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
